export default {
  // 租户管理
  lesseeManage: {
    // 新增租户
    add: "authManage_lesseeManage_add",
    // 租户详情
    detail: "authManage_lesseeManage_detail",
    // 删除租户
    delete: "authManage_lesseeManage_delete",
    // 启禁用租户
    enable: "authManage_lesseeManage_enable",
  },
  // 组织管理
  organizationManage: {
    // 新建组织
    add: "authManage_organizationManage_add",
    // 编辑组织
    edit: "authManage_organizationManage_edit",
    // 关联用户
    relevanceUser: "authManage_organizationManage_relevanceUser",
    // 删除组织
    delete: "authManage_organizationManage_delete",
  },
  // 角色管理
  roleManage: {
    // 新建角色
    add: "authManage_roleManage_add",
    // 删除角色
    delete: "authManage_roleManage_delete",
    // 编辑角色
    edit: "authManage_roleManage_edit",
    // 启禁用角色
    enable: "authManage_roleManage_enable",
    look: "authManage_roleManage_look"
  },
  // 账号管理
  accountManage: {
    // 添加账号
    add: "authManage_accountManage_add",
    // 账号详情
    detail: "authManage_accountManage_detail",
    // 编辑账号
    edit: "authManage_accountManage_edit",
    // 删除账号
    delete: "authManage_accountManage_delete",
    // 启禁用账号
    enable: "authManage_accountManage_enable",
  },
};
