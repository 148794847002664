export const routeRegistered = {
  MenuManage: () => import("@/views/system/menuManage"),
  ProjectManage: () => import("@/views/baseSet/projectManage"),
  AddProject: () => import("@/views/baseSet/projectManage/addProject"),
  AccountManage: () => import("@/views/authManage/accountManage"),
  LesseeManage: () => import("@/views/authManage/lesseeManage"),
  OrganizationManage: () => import("@/views/authManage/organizationManage"),
  RoleManage: () => import("@/views/authManage/roleManage"),
  AddLesseeBaseInfo: () => import("@/views/authManage/lesseeManage/addLesseeBaseInfo"),
  AddRole: () => import("@/views/authManage/roleManage/addRole"),
  Templates: () => import("@/views/templates"),
  SpaceManage: () => import("@/views/baseSet/spaceManage"),
  AddSpace: () => import("@/views/baseSet/spaceManage/addSpace"),
  LookSpace: () => import("@/views/baseSet/spaceManage/lookSpace"),
  LookRole: () => import("@/views/authManage/roleManage/addRole"),
}