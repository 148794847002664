import { initialState } from "./globalState";

import config from "@/config";

const { OPERATION_MICRO_APP, BASE_MANAGE_APP,ANALYSIS_MICRO_APP, PARK_MICRO_APP, ASSET_MICRO_APP, INTEGRATED_MICRO_APP, ENTERPRISE_MICRO_APP, TALENT_MICRO_APP } = config;

import { initGlobalState } from 'qiankun';

const flag = window.location.href.includes('kcjt')
console.log(flag, '$$$0000')

const microApps = [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用，这里我们使用 config 配置
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   */
  {
    name: "operation",
    entry: OPERATION_MICRO_APP,
    activeRule: `${flag ? '/kcjt/operation/' : '/operation/'}`,
  },
  {
    name: "analysis",
    entry: ANALYSIS_MICRO_APP,
    activeRule:  `${flag ? '/kcjt/analysis/' : '/analysis/'}`,
  },
  {
    name: "baseManage",
    entry: BASE_MANAGE_APP,
    activeRule: "/baseManage/",
  },
  {
    name: "park",
    entry: PARK_MICRO_APP,
    activeRule: `${flag ? '/kcjt/park/' : '/park/'}`,
  },
  {
    name: "asset",
    entry: ASSET_MICRO_APP,
    activeRule: `${flag ? '/kcjt/asset/' : '/asset/'}`,
  },
  {
    name: "integrated",
    entry: INTEGRATED_MICRO_APP,
    activeRule: `${flag ? '/kcjt/integrated/' : '/integrated/'}`,
  },
  {
    name: "enterprise",
    entry: ENTERPRISE_MICRO_APP,
    activeRule: `${flag ? '/kcjt/enterprise/' : '/enterprise/'}`,
  },
  {
    name: 'talent',
    entry: TALENT_MICRO_APP,
    activeRule: `${flag ? '/kcjt/talent/' : '/talent/'}`,
  }
];

/* 重构apps */
export const filterApps = () => {
  console.log(process.env)
  microApps.forEach((item) => {
    // 必选，微应用的容器节点的选择器或者 Element 实例。
    item.container = "#subapp-viewport";
    // 可选，主应用需要传递给微应用的数据。
    item.props = {
      routerBase: item.activeRule, // 下发基础路由
      globalState: initialState, // 下发全局数据方法
    };
  });
  return microApps;
};

/* qiankun全局声明周期钩子 */
export const microConfig = {
  beforeLoad: [
    (app) => {
      // 初始化 参数 state，state一定要起名初始化，这里我监听的是closeTab
      const actions = initGlobalState({ closeTab: '' });
      actions.onGlobalStateChange((state, prev) => {
        // state: 变更后的状态; prev 变更前的状态
        console.log('main state change', state, prev);

        if (state.closeTab !== '') {
          // vuex里面的modules里面的mutation，已经被全局使用，不想全局可以给命名空间，另外，直接调用mutation不太好，最好使用action，也就是dispatch方式
        }
      });
      console.log("%c before load", "background:#3a5ab0 ; padding: 1px; border-radius: 3px;  color: #fff", app);
    },
  ], // 挂载前回调
  beforeMount: [
    (app) => {
      console.log("%c before mount", "background:#7d9553 ; padding: 1px; border-radius: 3px;  color: #fff", app);
    },
  ], // 挂载后回调
  afterUnmount: [
    (app) => {
      console.log("%c after unload", "background:#d2525c ; padding: 1px; border-radius: 3px;  color: #fff", app);
    },
  ], // 卸载后回调
};
