/*
 * @Author: lizhijie429
 * @Date: 2021-08-20 09:02:14
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-08-20 09:11:28
 * @Description:
 */
import {getLocalStorage} from "@/utils/storage"
const config = {
  // 微应用配置
  OPERATION_MICRO_APP: process.env.VUE_APP_MICRO_OPERATION,
  ANALYSIS_MICRO_APP: process.env.VUE_APP_MICRO_ANALYSIS,
  BASE_MANAGE_APP: process.env.VUE_APP_DEFAULT_APP,
  PARK_MICRO_APP: process.env.VUE_APP_MICRO_PARK,
  ASSET_MICRO_APP: process.env.VUE_APP_MICRO_ASSET,
  INTEGRATED_MICRO_APP: process.env.VUE_APP_MICRO_INTEGRATED,
  ENTERPRISE_MICRO_APP: process.env.VUE_APP_MICRO_ENTERPRISE,
  TALENT_MICRO_APP: process.env.VUE_APP_MICRO_TALENT,
  // 后台接口配置
  API_URL: process.env.VUE_APP_API_URL,
};

export const CURRENT_ORIGIN = location.origin;
export const isDevelopment = process.env.NODE_ENV === "development";
export const IMG_API = isDevelopment ? "/api/aliyun" : CURRENT_ORIGIN + "/api/aliyun"; // 后边要加 + /oss/files/
export const USER_KEY = "user";

//是否是平台账户
export let isPlatformAccount

//03e8c19499c00000
export const getPlatformAccount = () => {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pre') {
    isPlatformAccount = getLocalStorage("user").tenantId === "03e8c19499c00000" ? true : false;
  } else {
    isPlatformAccount = getLocalStorage("user").tenantId === "1" ? true : false;
  }

}

//请求成功code码
export const SUCCESS_CODE = "200"

export default config;
