import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import Home from "@/views/home/Home.vue";
import Layout from "@/views/Layout";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { routeRegistered } from "./registered";

Vue.use(VueRouter);

// 路由
let constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { isTabs: false, isSide: false, moduleName: "main", title: "登录" },
  },
  {
    path: "/templates",
    name: "templates",
    component: routeRegistered.Templates,
    meta: { isTabs: false, isSide: false, moduleName: "main", title: "模板" },
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: process.env.VUE_APP_DEFAULT_APP, // 默认加载的路由
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: { isTabs: false, isSide: true, moduleName: "main", title: "工作台" },
      },
      {
        path: "/baseSet/spaceManage/lookSpace",
        name: "lookSpace",
        component: routeRegistered.LookSpace,
        meta: { isTabs: false, isSide: false, moduleName: "main", title: "查看空间" },
      },
      {
        path: "/baseSet/spaceManage/addSpace",
        name: "addSpace",
        component: routeRegistered.AddSpace,
        meta: { isTabs: false, isSide: false, moduleName: "main", title: "添加空间" },
      },
      {
        path: "/baseSet/spaceManage/addSpace",
        name: "addSpace",
        component: routeRegistered.AddSpace,
        meta: { isTabs: false, isSide: false, moduleName: "main", title: "添加空间" },
      },
      {
        path: "/authManage/roleManage/addRole",
        name: "addRole",
        component: routeRegistered.LookRole,
        meta: { isTabs: false, isSide: false, moduleName: "main", title: "添加空间" },
      },
    ],
  },
];

const createRouter = () => {
  const flag = window.location.href.includes('kcjt')
  return new VueRouter({
    base: `${ flag ? 'kcjt' : '' }`,
    mode: "history",
    routes: constantRoutes,
    isAddAsyncMenuData: false,
  });
};

// 处理重复点击同一个路由报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = createRouter();
/**
 * 重置注册的路由导航map
 * 主要是为了通过addRoutes方法动态注入新路由时，避免重复注册相同name路由
 */
const resetRouter = () => {
  const newRouter = createRouter();
  router && (router.matcher = newRouter.matcher);
  router.options.isAddAsyncMenuData = false;
};

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path === "/templates") {
    next();
  } else {
    // 菜单当前选中及页面持久
    if (to.path !== "/login") {
      store.commit("permission/UPDATE_CURRENT_MODULE_NAME", to.meta.moduleName);
      store.commit("permission/UPDATE_CURRENT_PAGE", to.path);
      // 如果路径有参数 要进行保存 防止刷新页面数据丢失
      if (to.query) {
        store.commit("permission/UPDATE_CURRENT_QUERY", to.query);
      }
    }
    // 首页的时候组装左侧导航数据
    if (to.path === "/home") {
      store.commit("permission/UPDATE_SUB_MENU", true);
    }
    const route = {
      path: "/",
      name: "Layout",
      component: Layout,
      children: [],
    };
    if (!router.options.isAddAsyncMenuData && to.path !== "/login") {
      store.dispatch("permission/generateRoutes").then((accessRoutes) => {
        // 根据用户权限生成可访问的路由表
        for (let i = 0, length = accessRoutes.length; i < length; i += 1) {
          const element = accessRoutes[i];
          if (element.name === "notfound") {
            router.addRoute(element);
          } else {
            if (element.meta.moduleName === "main") {
              route.children.push(element);
            } else {
              router.addRoute(element);
            }
          }
          if(element.name == '空间管理'){
            sessionStorage.removeItem('selectedOption')
            sessionStorage.removeItem('selectedName')
            store.dispatch('space/getTreeProject',element.meta.roleId)
          }
        }
        router.addRoute(route);
        router.options.isAddAsyncMenuData = true;

        next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
      }).catch(err=>{
        next(err)
      });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export { constantRoutes, resetRouter };

export default router;
