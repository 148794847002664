<template>
    <div>
        <div class="">
            <div class="displayFlex viewTitle">
                <div class="title">待办事项</div>
                <div class="projectTitle">
                    项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                        v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                        placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }" />
                </div>
            </div>
            <div v-if="dataList.length == 0" class="placeholderIcon">
                <img src="../../../assets/home/home (21).png" alt="" srcset="">
            </div>
            <div v-for="(item, index) in dataList" :key="index">
                <div v-if="item.type == 1" @click="checkBut(item)" class="messageBox">
                    <div>
                        <span>{{ item.reminderTypeName }}</span>
                        <span class="code">{{ item.number }}</span>
                        <span>等待审批</span>
                    </div>
                    <div class="messageBoxTime">
                        <span v-if="item.readState == 0" class="drop"></span>
                        <span>{{ item.createTime }}</span>
                    </div>
                </div>
                <div v-if="item.type == 2" @click="checkBut(item)" class="messageBox">
                    <div v-if="item.reminderType==1">
                        <span>回款编号</span>
                        <span class="code">{{ item.number }}</span>
                        <span>等待审批</span>
                    </div>
                    <div v-if="item.reminderType==2">
                        <span>发票号码</span>
                        <span class="code">{{ item.number }}</span>
                        <span>开票等待审批</span>
                    </div>
                    <div v-if="item.reminderType==3">
                        <span>合同编号</span>
                        <span class="code">{{ item.number }}</span>
                        <span>将于{{item.days}}天后收取费用，请注意催收。</span>
                    </div>
                    <div class="messageBoxTime">
                        <span v-if="item.readState == 0" class="drop"></span>
                        <span>{{ item.createTime }}</span>
                    </div>
                </div>
                <div v-if="item.type == 3" @click="checkBut(item)" class="messageBox">
                    <div>
                        <span>任务</span>
                        <span class="code">{{ item.number }}</span>
                        <span>发起{{ item.reminderTypeName }}等待审批</span>
                    </div>
                    <div class="messageBoxTime">
                        <span v-if="item.readState == 0" class="drop"></span>
                        <span>{{ item.createTime }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="messageBox">
                <div>
                    <span>租赁合同</span>
                    <span class="code">ZL-2023-002</span>
                    <span>等待审批</span>
                </div>
                <div class="messageBoxTime">
                    <span class="drop"></span>
                    <span>2024-05-05</span>
                </div>
            </div> -->
            <div class="pagination" v-if="total">
                <a-pagination show-size-changer @change="changePage" :page-size="pageSize" v-model="current"
                    :total="total" @showSizeChange="sizeChange" />
            </div>

        </div>

    </div>
</template>

<script>
import * as api from "@/api/home";
import { matterType } from '../index.js'
import store from "@/store";
export default {
    props: ['projectList', 'roleId'],
    data() {
        return {
            total: 0,
            current: 1,
            pageSize: 10,
            projectId: null,
            dataList: [],
            tabsList: [],
            matterType,
            fieldName: {
                value: 'id',
                key: 'id',
                title: 'project_name',
                children: 'child',
            }
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            let obj = {
                pageNum: this.current,
                pageSize: this.pageSize,
                roleId: this.roleId,
                projectId: this.projectId
            }
            await api.needMatterGetList(obj).then((res) => {
                if (res.code === '200' && res.data.list) {
                    res.data.list.forEach(element => {
                        let list = this.matterType[Number(element.type) - 1]
                        element.reminderTypeName = list[Number(element.reminderType) - 1]
                    });
                    this.dataList = res.data.list;
                    this.total = res.data.total;
                } else {
                    this.dataList = [];
                    this.total = 0;
                }
            });
        },
        projectChange() {
            this.current = 1
            this.getData()
        },
        changePage(page) {
            this.current = page
            this.getData()
        },
        sizeChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.getData()

        },
        //待办事项 
        async checkBut(item) {
            this.tabsList = []
            await api.needMatterUpdateReadState(item.id).then(async (res) => {
                if (res.code == 200) {
                    let moduleName = item.type == 3 ? 'integrated' : 'asset'
                    let menuList = store.state.permission.menuList
                    const menu = menuList.filter((element) => {
                        return element.moduleName === moduleName;
                    });
                    this.$store.commit("permission/UPDATE_SUB_MENU", menu[0].menuList);
                    // store.commit('tabs/UPDATE_TABS_LIST', [false]);
                    // 跳转任务审批页面
                    if (item.type == 3) {
                        await this.screenDate(menu[0].menuList, '/integrated/taskManage/toExamine')
                        store.commit('tabs/UPDATE_TABS_LIST', this.tabsList[0]);
                        this.$router.push({
                            path: '/integrated/taskManage/toExamine'
                        })
                        // 跳转财务管理
                    } else if (item.type == 2) {
                        if (item.reminderType == 1) {
                            await this.screenDate(menu[0].menuList, '/asset/revenueManagement/billReturnedRecord')
                            store.commit('tabs/UPDATE_TABS_LIST', this.tabsList[0]);
                            this.$router.push({
                                path: '/asset/revenueManagement/billReturnedRecord'
                            })
                        } else if (item.reminderType == 2) {
                            await this.screenDate(menu[0].menuList, '/asset/revenueManagement/makeFaPiaoManagement')
                            store.commit('tabs/UPDATE_TABS_LIST', this.tabsList[0]);
                            this.$router.push({
                                path: '/asset/revenueManagement/makeFaPiaoManagement'
                            })
                        } else if (item.reminderType == 3) {
                            this.$router.push({
                                path: '/asset/contractManagement/leaseContract/fulfillmentCondition',
                                query: {
                                    id: item.matterId,
                                    contractName:item.contractName
                                }
                            })
                        }
                        // 跳转合同管理
                    } else if (item.type == 1) {
                        if (item.reminderType == 1) {
                            await this.screenDate(menu[0].menuList, '/asset/contractManagement/leaseContract')
                            store.commit('tabs/UPDATE_TABS_LIST', this.tabsList[0]);
                            this.$router.push({
                                path: '/asset/contractManagement/leaseContract'
                            })
                        } else if (item.reminderType == 2) {
                            await this.screenDate(menu[0].menuList, '/asset/contractManagement/nonLeaseContract')
                            store.commit('tabs/UPDATE_TABS_LIST', this.tabsList[0]);
                            this.$router.push({
                                path: '/asset/contractManagement/nonLeaseContract'
                            })
                        }
                    }

                }
            });
        },
        //筛选路由相等的数据
        screenDate(list, path) {
            list.forEach(item => {
                if (path == item.menuPath) {
                    this.tabsList.push(item)
                }
                if (item.children && item.children.length) {
                    this.screenDate(item.children, path);
                }
            });
        }
    }


}
</script>

<style lang="scss" scoped>
.displayFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}

.messageBox {
    display: flex;
    padding: 14px 0px;
    border-bottom: 1px solid #EEEEEE;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .code {
        color: #FB3D4C;
    }

    .messageBoxTime {
        display: flex;
        align-items: center;

        .drop {
            width: 4px;
            height: 4px;
            background: #FB3D4C;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.placeholderIcon {
    display: flex;
    justify-content: center;

    img {
        width: 300px;
        margin-top: 30px;
    }
}
</style>